import 'stylesheets/pages/errors.scss';

$(document).ready(function() {
  window.onload = function () {
    document.body.classList.add("bg-error");
  };

  if (document.body.className === 'upscroll_inactive') {
    // $('.header').toggleClass('no-scroll'); // Should be applied on template level if needed
    
    $(window).scroll(function() {
      if (parseInt($(window).width()) > 991) {
        $('.header, .header__top, .header__logo, .header__contact, .header__nav')
        .toggleClass('scroll', $(this).scrollTop() > $('header').height());
      } else {
        $('.header').removeClass('scroll');
      }
    });
  }
});